<template>
  <div class="card p-shadow-6">
    <div class="p-grid p-col-12 p-lg-12">
      <h1>Links Utéis</h1>

      <div
        class="p-grid p-col-12 p-lg-12"
        v-for="category in listCategories"
        :key="category"
      >
        <Fieldset :legend="category" :toggleable="true" style="width: 100%">
          <div class="p-grid p-col-12 p-lg-12">
            <div
              class="card p-shadow-2 p-col-6 p-lg-2 p-mr-2"
              v-for="link in getCategoryLink(category)"
              :key="link.id"
              style="cursor: pointer"
              v-on:click="goTo(link.url)"
            >
              <h3 class="p-text-center">{{ link.name }}</h3>
              <p>
                {{ link.comment }}
              </p>
            </div>
          </div>
        </Fieldset>
      </div>
    </div>
  </div>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import linksService from "../services/linksUseful.service";
export default {
  name: "LinksUseful",
  data() {
    return {
      loading: true,
      linksList: [],
      listCategories: [],
    };
  },
  async created() {
    await this.getUserLinks();
    await this.setCategories();
    this.loading = false;
  },
  methods: {
    getUserLinks() {
      return linksService
        .getUserLinks(this.$store.state.auth.user.id)
        .then((response) => {
          return (this.linksList = response);
        });
    },
    setCategories() {
      return (this.listCategories = [
        ...new Set(this.linksList.map((link) => link.categoryname)),
      ]);
    },
    getCategoryLink(categoryName) {
      return this.linksList.filter(
        (link) => link.categoryname === categoryName
      );
    },
    goTo(url) {
      window.open(url, '_blank');
    },
  },
};
</script>