import axios from "axios";
import authHeader from "./auth-header";
import AuthService from "./auth.service";
import { config } from "../config";

class LinksUsefulService {
  getAllLinks() {
    return axios
      .get(`${config["apiUrl"]}linksUseful`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getAllLinksCategories() {
    return axios
      .get(`${config["apiUrl"]}linksUsefulCategories`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  createLink(bodyParms) {
    return axios
      .post(
        `${config["apiUrl"]}linksUseful`,
        bodyParms,
        {
          headers: authHeader()
        }
      )
      .then(response => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch(error => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  updateLink(id, bodyParms) {
    return axios
      .patch(
        `${config["apiUrl"]}linksUseful/${id}`,
        bodyParms,
        {
          headers: authHeader()
        }
      )
      .then(response => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch(error => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  createCategory(bodyParms) {
    return axios
      .post(
        `${config["apiUrl"]}linksUsefulCategories`,
        bodyParms,
        {
          headers: authHeader()
        }
      )
      .then(response => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch(error => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  updateCategory(id, bodyParms) {
    return axios
      .patch(
        `${config["apiUrl"]}linksUsefulCategories/${id}`,
        bodyParms,
        {
          headers: authHeader()
        }
      )
      .then(response => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch(error => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  getUserLinks(userId) {
    return axios
      .get(`${config["apiUrl"]}linksUseful/user/${userId}`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
}
export default new LinksUsefulService();
